import {
  List,
  Datagrid,
  TextField,
  ResourceComponentProps,
  Create,
  SimpleForm,
  TextInput,
  required,
  SelectInput,
  ChipField,
  ArrayInput,
  SimpleFormIterator,
  Edit,
  ShowActionsProps,
  TopToolbar,
  EditButton,
  DeleteButton,
  Show,
  SimpleShowLayout,
  ArrayField,
  ReferenceInput,
} from "react-admin";

export class UserUpdateCommand {
  private role: string;
  private permissions: UpdateCommandUserPermission[] = [];

  constructor(data: any) {
    this.role = data.role ?? "";

    const permissionsData = data.permissions as [any];
    permissionsData.forEach((data) =>
      this.permissions.push(UpdateCommandUserPermission.fromJson(data))
    );
  }

  static fromJson(data: any) {
    return new UserUpdateCommand(data);
  }
}

class UpdateCommandUserPermission {
  private id: string | undefined;
  private siteId: string;
  private machineInstanceId: string | undefined;

  constructor(data: any) {
    this.id = data.id ?? undefined;
    this.siteId = data.siteId ?? "";
    this.machineInstanceId = data.machineInstanceId ?? undefined;
  }

  static fromJson(data: any) {
    return new UpdateCommandUserPermission(data);
  }
}

const UserRoles = [
  { id: "Basic", name: "Basic" },
  { id: "ClientAdministrator", name: "Client Administrator" },
  { id: "Administrator", name: "Administrator" },
];

const UsersFilterFilters = [
  <ReferenceInput source="clientId" reference="client">
    <SelectInput optionText="name" optionValue="id" />
  </ReferenceInput>,
  <SelectInput source="role" choices={UserRoles} />,
  <TextInput source="email" />,
];

export const UserList = (props: ResourceComponentProps) => (
  <List {...props} filters={UsersFilterFilters}>
    <Datagrid rowClick="show">
      <TextField source="email" />
      <TextField source="id" />
      <TextField source="client.name" label="Client" />
      <ChipField source="role" />
    </Datagrid>
  </List>
);

export const UserCreate = (props: ResourceComponentProps) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="email" validate={required()} />
      <ReferenceInput
        source="clientId"
        reference="client"
        validate={required()}>
        <SelectInput optionText="name" optionValue="id" />
      </ReferenceInput>
      <SelectInput source="role" choices={UserRoles} validate={required()} />

      <ArrayInput source="permissions" defaultValue={[]}>
        <SimpleFormIterator>
          <ReferenceInput
            source="siteId"
            reference="site"
            label="Site"
            allowEmpty>
            <SelectInput optionText="name" optionValue="id" />
          </ReferenceInput>

          <ReferenceInput
            source="machineInstanceId"
            reference="machineInstance"
            label="Machine"
            allowEmpty>
            <SelectInput optionText="id" optionValue="id" />
          </ReferenceInput>
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
);

export const UserEdit = (props: ResourceComponentProps) => (
  <Edit {...props}>
    <SimpleForm>
      <SelectInput source="role" choices={UserRoles} validate={required()} />

      <ArrayInput source="permissions" defaultValue={[]}>
        <SimpleFormIterator>
          <TextInput source="id" label="Id" disabled />
          <ReferenceInput
            source="siteId"
            reference="site"
            label="Site"
            allowEmpty>
            <SelectInput optionText="name" optionValue="id" />
          </ReferenceInput>

          <ReferenceInput
            source="machineInstanceId"
            reference="machineInstance"
            label="Machine"
            allowEmpty>
            <SelectInput optionText="id" optionValue="id" />
          </ReferenceInput>
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Edit>
);

const UserShowActions = (props: ShowActionsProps) => (
  <TopToolbar>
    <EditButton basePath={props.basePath} record={props.data} />
    <DeleteButton basePath={props.basePath} record={props.data} />
  </TopToolbar>
);

export const UserShow = (props: ResourceComponentProps) => (
  <Show actions={<UserShowActions />} {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="email" />
      <TextField source="clientId" label="Client" />
      <TextField source="role" />

      <ArrayField source="permissions">
        <Datagrid>
          <TextField source="id" />
          <TextField source="siteId" />
          <TextField source="machineInstanceId" />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);
