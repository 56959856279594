import { Layout, AppBar, UserMenu, LayoutProps, AppBarProps, UserMenuProps } from "react-admin";
import { Auth } from "aws-amplify";
import { ListItemIcon, MenuItem } from "@material-ui/core";
import LogoutIcon from '@material-ui/icons/PowerSettingsNew';

const AmplifyLogoutButton = () => (
    <MenuItem
        onClick={() => { Auth.signOut().then(() => { window.location.replace("/") }) }}>
        <ListItemIcon >
            <LogoutIcon />
        </ListItemIcon>
        {"Logout"}
    </MenuItem>
)

const CustomUserMenu = (props: UserMenuProps) => <UserMenu {...props} logout={<AmplifyLogoutButton />} />
const CustomAppBar = (props: AppBarProps) => <AppBar {...props} userMenu={<CustomUserMenu />} />;

export const CustomLayout = (props: LayoutProps) => <Layout {...props} appBar={CustomAppBar} />;