import {
  List,
  Datagrid,
  TextField,
  ResourceComponentProps,
  Create,
  SimpleForm,
  TextInput,
  DateField,
  Show,
  SimpleShowLayout,
  TopToolbar,
  DeleteButton,
  ShowActionsProps,
  Edit,
  required,
  ReferenceInput,
  SelectInput,
  ReferenceField,
  NumberInput,
  EditButton,
} from "react-admin";

export class SiteUpdateCommand {
  public name: string;
  public country: string;
  public location: string;
  public latitude: string;
  public longitude: string;

  constructor(data: any) {
    this.name = data.name ?? "";
    this.country = data.country ?? "";
    this.location = data.location ?? "";
    this.latitude = data.latitude ?? "";
    this.longitude = data.longitude ?? "";
  }

  static fromJson(data: any) {
    return new SiteUpdateCommand(data);
  }
}

const SiteFilters = [
  <ReferenceInput source="clientId" reference="client">
    <SelectInput optionText="name" optionValue="id" />
  </ReferenceInput>,
];

export const SiteList = (props: ResourceComponentProps) => (
  <List {...props} filters={SiteFilters}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="client.name" label="Client" />
      <DateField source="createdAt" showTime={true} />
      <TextField source="country" />
      <TextField source="location" />
    </Datagrid>
  </List>
);

export const SiteCreate = (props: ResourceComponentProps) => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput
        source="clientId"
        reference="client"
        validate={required()}>
        <SelectInput optionText="name" optionValue="id" />
      </ReferenceInput>

      <TextInput source="name" validate={required()} />
      <TextInput source="country" validate={required()} />
      <TextInput source="location" validate={required()} />
      <NumberInput source="latitude" validate={required()} />
      <NumberInput source="longitude" validate={required()} />
    </SimpleForm>
  </Create>
);

export const SiteEdit = (props: ResourceComponentProps) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name" validate={required()} />
      <TextInput source="country" validate={required()} />
      <TextInput source="location" validate={required()} />
      <NumberInput source="latitude" validate={required()} />
      <NumberInput source="longitude" validate={required()} />
    </SimpleForm>
  </Edit>
);

const SiteShowActions = (props: ShowActionsProps) => (
  <TopToolbar>
    <EditButton basePath={props.basePath} record={props.data} />
    <DeleteButton basePath={props.basePath} record={props.data} />
  </TopToolbar>
);

export const SiteShow = (props: ResourceComponentProps) => (
  <Show actions={<SiteShowActions />} {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField
        label="Client"
        source="clientId"
        reference="client"
        link="show">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="createdAt" />
      <TextField source="country" />
      <TextField source="location" />
      <TextField source="latitude" />
      <TextField source="longitude" />
    </SimpleShowLayout>
  </Show>
);
