import React, { useState } from "react";
import { Admin, Resource } from "react-admin";
import { CustomDataProvider } from "network/DataProvider";
import {
  MachineDefinitionList,
  MachineDefinitionCreate,
  MachineDefinitionShow,
  MachineDefinitionEdit,
} from "app/resources/MachineDefinition";
import { createBrowserHistory } from "history";
import { AmplifyAuthenticator, AmplifySignIn } from "@aws-amplify/ui-react";
import { CustomLayout } from "./layout/CustomLayout";
import {
  ClientCreate,
  ClientEdit,
  ClientList,
  ClientShow,
} from "./resources/Clients";
import ClientIcon from "@material-ui/icons/Face";
import MachineInstanceIcon from "@material-ui/icons/Settings";
import UsersIcon from "@material-ui/icons/People";
import SitesIcon from "@material-ui/icons/Business";
import {
  MachineInstanceCreate,
  MachineInstanceEdit,
  MachineInstanceList,
  MachineInstanceShow,
} from "./resources/MachineInstances";
import { UserCreate, UserEdit, UserList, UserShow } from "./resources/Users";
import { SiteCreate, SiteEdit, SiteList, SiteShow } from "./resources/Sites";

const history = createBrowserHistory();
const dataProvider = new CustomDataProvider();

const App = () => {
  const [authState, setAuthState] = useState<string>();

  return (
    <>
      {authState === "signedin" ? (
        <Admin
          history={history}
          dataProvider={dataProvider}
          layout={CustomLayout}>
          <Resource
            name={"machineDefinition"}
            options={{ label: "Machine Definitions" }}
            list={MachineDefinitionList}
            create={MachineDefinitionCreate}
            show={MachineDefinitionShow}
            edit={MachineDefinitionEdit}
          />
          <Resource
            name={"client"}
            options={{ label: "Clients" }}
            icon={ClientIcon}
            list={ClientList}
            create={ClientCreate}
            show={ClientShow}
            edit={ClientEdit}
          />
          <Resource
            name={"site"}
            options={{ label: "Sites" }}
            icon={SitesIcon}
            list={SiteList}
            create={SiteCreate}
            show={SiteShow}
            edit={SiteEdit}
          />
          <Resource
            name={"machineInstance"}
            options={{ label: "Machine Instances" }}
            icon={MachineInstanceIcon}
            list={MachineInstanceList}
            create={MachineInstanceCreate}
            show={MachineInstanceShow}
            edit={MachineInstanceEdit}
          />
          <Resource
            name={"user"}
            options={{ label: "Users" }}
            icon={UsersIcon}
            list={UserList}
            create={UserCreate}
            show={UserShow}
            edit={UserEdit}
          />
        </Admin>
      ) : (
        <AmplifyAuthenticator
          handleAuthStateChange={(state: string, _: any) => {
            setAuthState(state);
          }}>
          <AmplifySignIn slot="sign-in" hideSignUp={true} />
        </AmplifyAuthenticator>
      )}
    </>
  );
};

export default App;
