import { Auth } from "aws-amplify";
import { default as Axios } from "axios";
import environment from "configurations";

// Create axios instances
const configuration = {
  baseURL: `${environment.api}`,
  headers: {
    "Content-Type": "application/json",
  },
};

const httpClient = Axios.create(configuration);

httpClient.interceptors.request.use(
  (config) => {
    /* eslint-disable no-async-promise-executor */
    return new Promise(async (resolve) => {
      const currentSession = await Auth.currentSession()

      if (currentSession) {
        config.headers.Authorization = `Bearer ${currentSession.getIdToken().getJwtToken()}`
        return resolve(config)
      } else {
        return resolve(config)
      }
    })
  },
  (err) => {
    return Promise.reject(err)
  }
)

export default httpClient;
