import axios, { AxiosResponse } from "axios";
import {
  List,
  Datagrid,
  TextField,
  ResourceComponentProps,
  Create,
  TextInput,
  DateField,
  Show,
  TopToolbar,
  DeleteButton,
  ShowActionsProps,
  Edit,
  required,
  ArrayInput,
  SimpleFormIterator,
  ArrayField,
  EditButton,
  useNotify,
  useRedirect,
  ImageField,
  ImageInput,
  SelectInput,
  ChipField,
  TabbedForm,
  FormTab,
  BooleanInput,
  BooleanField,
  TabbedShowLayout,
  Tab,
  NumberInput,
  NumberField,
} from "react-admin";

export class MachineDefinitionUpdateCommand {
  private name: string;
  private updateImage: string;
  private telemetry: UpdateMachineTelemetryCommand[] = [];
  private alarms: object;
  private notifications: object;
  private graphs: object;

  constructor(data: any) {
    this.name = data.name ?? "";
    this.updateImage = data.updateImage ?? false;

    const telemetryData = data.telemetry as [any];
    telemetryData.forEach((data) =>
      this.telemetry.push(UpdateMachineTelemetryCommand.fromJson(data))
    );

    this.alarms = data.alarms;
    this.notifications = data.notifications;
    this.graphs = data.graphs;
  }

  static fromJson(data: any) {
    return new MachineDefinitionUpdateCommand(data);
  }
}

class UpdateMachineTelemetryCommand {
  private id: string | undefined;
  private name: string;
  private label: string;
  private unit: string;
  private type: string;
  private log: boolean;
  private sms: boolean;
  private dataType: string;

  constructor(data: any) {
    this.id = data.id ?? undefined;
    this.name = data.name ?? "";
    this.label = data.label;
    this.unit = data.unit;
    this.type = data.type;
    this.log = data.log;
    this.sms = data.sms;
    this.dataType = data.dataType;
  }

  static fromJson(data: any) {
    return new UpdateMachineTelemetryCommand(data);
  }
}

const TelemetryTypes = [
  { id: "Telemetry", name: "Telemetry" },
  { id: "WorkingStatus", name: "Working Status" },
  { id: "WorkingHours", name: "Working Hours" },
  { id: "WorkingArea", name: "Working Area" },
  { id: "NumberOfWorkSessions", name: "Number Of Work Sessions" },
  { id: "TotalWires", name: "Total Wires" },
  { id: "WireRef", name: "Wire Ref" },
  { id: "CutHours", name: "Cut Hours" },
  { id: "CutType", name: "Cut Type" },
  { id: "CutPosition", name: "Cut Position" },
  { id: "AutoPilot", name: "Auto Pilot" },
  { id: "TractionSpeed", name: "Traction Speed" },
  { id: "GSMType", name: "GSM Type" },
  { id: "GSMSignal", name: "GSM Signal" },
  { id: "GSMOperator", name: "GSM Operator" },
  { id: "Alarm", name: "Alarm" },
  { id: "Notification", name: "Notification" },
];

const TelemetryDataTypes = [
  { id: "Double", name: "Double" },
  { id: "Boolean", name: "Boolean" },
  { id: "String", name: "String" },
];

const GraphTypes = [
  { id: "Graph", name: "Graph" },
  { id: "LastValue", name: "Last Value" },
  { id: "History", name: "History" },
];

export const MachineDefinitionList = (props: ResourceComponentProps) => (
  <List {...props}>
    <Datagrid rowClick="show">
      <TextField source="name" />
      <TextField source="id" />
      <DateField source="createdAt" />
    </Datagrid>
  </List>
);

export const MachineDefinitionCreate = (props: ResourceComponentProps) => {
  const notify = useNotify();
  const redirect = useRedirect();
  let imageToUpload: File;

  // Remove file raw data from creation command
  const transform = (data: any) => {
    imageToUpload = data.image.rawFile;

    const dataToGo = data;
    delete dataToGo.image;
    return dataToGo;
  };

  const onSuccess = (successResponse: any) => {
    uploadImage(
      successResponse.data.imageUploadLink,
      imageToUpload,
      (result) => {
        notify("ra.notification.created", "info", {
          messageArgs: { smart_count: 1 },
        });
        redirect(
          "show",
          props.basePath,
          successResponse.data.id,
          successResponse.data.machineDefinition
        );
      },
      (err) => {
        notify("ra.notification.created", "info", {
          messageArgs: { smart_count: 1 },
        });
        redirect(
          "show",
          props.basePath,
          successResponse.data.id,
          successResponse.data.machineDefinition
        );
      }
    );
  };

  return (
    <Create {...props} transform={transform} onSuccess={onSuccess}>
      <TabbedForm>
        <FormTab label="Sources">
          <TextInput source="name" validate={required()} />
          <ImageInput
            source="image"
            label="Machine Image"
            accept="image/*"
            multiple={false}
            validate={required()}>
            <ImageField source="src" title="title" />
          </ImageInput>
          <ArrayInput source="telemetry" validate={required()}>
            <SimpleFormIterator>
              <TextInput
                source="name"
                options={{ label: "Name" }}
                validate={required()}
              />
              <TextInput
                source="label"
                options={{ label: "Label" }}
                validate={required()}
              />
              <TextInput
                source="unit"
                options={{ label: "Unit" }}
                validate={required()}
              />
              <SelectInput
                source="type"
                options={{ label: "Telemetry Type" }}
                choices={TelemetryTypes}
                validate={required()}
              />
              <SelectInput
                source="dataType"
                options={{ label: "Data Type" }}
                choices={TelemetryDataTypes}
                validate={required()}
              />
              <BooleanInput
                source="log"
                label={"Log changes?"}
                defaultValue={false}
                validate={required()}
              />
              <BooleanInput
                source="sms"
                label={"Send SMS?"}
                defaultValue={false}
                validate={required()}
              />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>

        <FormTab label="Alarms">
          <ArrayInput source="alarms" validate={required()}>
            <SimpleFormIterator>
              <TextInput
                source="id"
                label={"Sensor Id"}
                validate={required()}
              />

              <ArrayInput
                source="definition"
                label={"Alarm Definition"}
                validate={required()}>
                <SimpleFormIterator>
                  <TextInput
                    source="name"
                    label={"Name"}
                    validate={required()}
                  />
                  <TextInput
                    source="text"
                    label={"Text"}
                    validate={required()}
                  />
                  <BooleanInput
                    source="sms"
                    label={"Send SMS?"}
                    defaultValue={false}
                    validate={required()}
                  />
                </SimpleFormIterator>
              </ArrayInput>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>

        <FormTab label="Notifications">
          <ArrayInput source="notifications" validate={required()}>
            <SimpleFormIterator>
              <TextInput
                source="id"
                label={"Sensor Id"}
                validate={required()}
              />
              <ArrayInput
                source="definition"
                label={"Notification Definition"}
                validate={required()}>
                <SimpleFormIterator>
                  <TextInput
                    source="name"
                    label={"Name"}
                    validate={required()}
                  />
                  <TextInput
                    source="text"
                    label={"Text"}
                    validate={required()}
                  />
                  <BooleanInput
                    source="sms"
                    label={"Send SMS?"}
                    defaultValue={false}
                    validate={required()}
                  />
                </SimpleFormIterator>
              </ArrayInput>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>

        <FormTab label="Graphs">
          <ArrayInput source="graphs" validate={required()}>
            <SimpleFormIterator>
              <TextInput source="label" label="label" validate={required()} />

              <TextInput source="valueFormatter" label="Value Formatter" />

              <NumberInput
                source="lastMinutes"
                label="Show last minutes"
                validate={required()}
              />

              <BooleanInput
                source="fullWidth"
                label="Full Width"
                validate={required()}
                defaultValue={false}
              />
              <SelectInput
                source="graphType"
                options={{ label: "Graph Type" }}
                choices={GraphTypes}
                validate={required()}
              />

              <ArrayInput
                source="dataSources"
                label="Sources"
                validate={required()}>
                <SimpleFormIterator disableReordering>
                  <TextInput
                    source="sensorId"
                    label="Sensor Id"
                    validate={required()}
                  />
                  <TextInput
                    source="color"
                    label="Line color"
                    validate={required()}
                  />
                </SimpleFormIterator>
              </ArrayInput>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export const MachineDefinitionEdit = (props: ResourceComponentProps) => {
  const notify = useNotify();
  const redirect = useRedirect();

  let imageToUpload: File;

  // Remove file raw data from creation command
  const transform = (data: any) => {
    if (typeof data.image == "object") {
      data = {
        updateImage: true,
        ...data,
      };
      imageToUpload = data.image.rawFile;
    }

    return data;
  };

  const onSuccess = (successResponse: any) => {
    if (imageToUpload && successResponse.data.imageUploadLink) {
      uploadImage(
        successResponse.data.imageUploadLink,
        imageToUpload,
        (result) => {
          notify("ra.notification.created", "info", {
            messageArgs: { smart_count: 1 },
          });
          redirect(
            "show",
            props.basePath,
            successResponse.data.id,
            successResponse.data.machineDefinition
          );
        },
        (err) => {
          notify("ra.notification.created", "info", {
            messageArgs: { smart_count: 1 },
          });
          redirect(
            "show",
            props.basePath,
            successResponse.data.id,
            successResponse.data.machineDefinition
          );
        }
      );
    } else {
      notify("ra.notification.created", "info", {
        messageArgs: { smart_count: 1 },
      });
      redirect(
        "show",
        props.basePath,
        successResponse.data.id,
        successResponse.data.machineDefinition
      );
    }
  };

  return (
    <Edit
      {...props}
      mutationMode="pessimistic"
      transform={transform}
      onSuccess={onSuccess}>
      <TabbedForm>
        <FormTab label="summary">
          <TextInput source="name" validate={required()} />
          <ImageInput
            source="image"
            label="Machine Image"
            accept="image/*"
            multiple={false}
            validate={required()}>
            <PreviewImage source="src" />
          </ImageInput>
          <ArrayInput source="telemetry" validate={required()}>
            <SimpleFormIterator>
              <TextInput
                source="name"
                options={{ label: "Name" }}
                validate={required()}
              />
              <TextInput
                source="label"
                options={{ label: "Label" }}
                validate={required()}
              />
              <TextInput
                source="unit"
                options={{ label: "Unit" }}
                validate={required()}
              />
              <SelectInput
                source="type"
                options={{ label: "Telemetry Type" }}
                choices={TelemetryTypes}
                validate={required()}
              />
              <SelectInput
                source="dataType"
                options={{ label: "Data Type" }}
                choices={TelemetryDataTypes}
                validate={required()}
              />
              <BooleanInput
                source="log"
                label={"Log changes?"}
                defaultValue={false}
                validate={required()}
              />
              <BooleanInput
                source="sms"
                label={"Send SMS?"}
                defaultValue={false}
                validate={required()}
              />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>

        <FormTab label="Alarms">
          <ArrayInput source="alarms" validate={required()}>
            <SimpleFormIterator>
              <TextInput
                source="id"
                label={"Sensor Id"}
                validate={required()}
              />
              <ArrayInput
                source="definition"
                label={"Alarm Definition"}
                validate={required()}>
                <SimpleFormIterator>
                  <TextInput
                    source="name"
                    label={"Name"}
                    validate={required()}
                  />
                  <TextInput
                    source="text"
                    label={"Text"}
                    validate={required()}
                  />
                  <BooleanInput
                    source="sms"
                    label={"Send SMS?"}
                    defaultValue={false}
                    validate={required()}
                  />
                </SimpleFormIterator>
              </ArrayInput>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>

        <FormTab label="Notifications">
          <ArrayInput source="notifications" validate={required()}>
            <SimpleFormIterator>
              <TextInput
                source="id"
                label={"Sensor Id"}
                validate={required()}
              />
              <ArrayInput
                source="definition"
                label={"Notification Definition"}
                validate={required()}>
                <SimpleFormIterator>
                  <TextInput
                    source="name"
                    label={"Name"}
                    validate={required()}
                  />
                  <TextInput
                    source="text"
                    label={"Text"}
                    validate={required()}
                  />
                  <BooleanInput
                    source="sms"
                    label={"Send SMS?"}
                    defaultValue={false}
                    validate={required()}
                  />
                </SimpleFormIterator>
              </ArrayInput>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>

        <FormTab label="Graphs">
          <ArrayInput source="graphs" validate={required()}>
            <SimpleFormIterator>
              <TextInput source="label" label="Label" validate={required()} />

              <TextInput source="valueFormatter" label="Value Formatter" />

              <NumberInput
                source="lastMinutes"
                label="Show last minutes"
                validate={required()}
              />

              <BooleanInput
                source="fullWidth"
                label="Full Width"
                validate={required()}
                defaultValue={false}
              />
              <SelectInput
                source="graphType"
                options={{ label: "Graph Type" }}
                choices={GraphTypes}
                validate={required()}
              />

              <ArrayInput
                source="dataSources"
                label="Sources"
                validate={required()}>
                <SimpleFormIterator disableReordering>
                  <TextInput
                    source="sensorId"
                    label="Sensor Id"
                    validate={required()}
                  />
                  <TextInput
                    source="color"
                    label="Line color"
                    validate={required()}
                  />
                </SimpleFormIterator>
              </ArrayInput>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

const MachineDefinitionShowActions = (props: ShowActionsProps) => (
  <TopToolbar>
    <EditButton basePath={props.basePath} record={props.data} />
    <DeleteButton basePath={props.basePath} record={props.data} />
  </TopToolbar>
);

export const MachineDefinitionShow = (props: ResourceComponentProps) => (
  <Show actions={<MachineDefinitionShowActions />} {...props}>
    <TabbedShowLayout>
      <Tab label="Sources">
        <TextField source="name" />
        <ImageField source="image" />
        <DateField source="createdAt" />
        <DateField source="lastUpdatedAt" />

        <ArrayField source="telemetry">
          <Datagrid>
            <TextField source="name" />
            <TextField source="label" />
            <TextField source="unit" />
            <ChipField source="type" />
            <ChipField source="dataType" />
            <BooleanField source="log" />
            <BooleanField source="sms" />
          </Datagrid>
        </ArrayField>
      </Tab>

      <Tab label="Alarms">
        <ArrayField source="alarms">
          <Datagrid>
            <TextField source={"id"} label={"Telemetry Id"} />
            <ArrayField source="definition" label={"Definition"}>
              <Datagrid>
                <TextField source="name" />
                <TextField source="text" />
                <BooleanField source="sms" />
              </Datagrid>
            </ArrayField>
          </Datagrid>
        </ArrayField>
      </Tab>

      <Tab label="Notifications">
        <ArrayField source="notifications">
          <Datagrid>
            <TextField source={"id"} label={"Telemetry Id"} />
            <ArrayField source="definition" label={"Definition"}>
              <Datagrid>
                <TextField source="name" />
                <TextField source="text" />
                <BooleanField source="sms" />
              </Datagrid>
            </ArrayField>
          </Datagrid>
        </ArrayField>
      </Tab>

      <Tab label="Graphs">
        <ArrayField source="graphs">
          <Datagrid>
            <TextField source="label" label="label" />
            <TextField source="valueFormatter" label="Value Formatter" />
            <NumberField source="lastMinutes" label="Show last minutes" />
            <BooleanField source="fullWidth" label="Full Width" />
            <ChipField source="graphType" label="Graph Type" />
            <ArrayField source="dataSources" label="Sources">
              <Datagrid>
                <TextField source="sensorId" />
                <TextField source="color" />
              </Datagrid>
            </ArrayField>
          </Datagrid>
        </ArrayField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

const PreviewImage = ({ record, source }: any) => {
  if (typeof record == "string") {
    record = {
      [source]: record,
    };
  }
  return <ImageField record={record} source={source} />;
};

const uploadImage = (
  url: string,
  file: File,
  onSuccess: (success: AxiosResponse<any>) => void,
  onError: (err: Error) => void
) => {
  axios
    .put(url, file, { headers: { "Content-Type": file.type } })
    .then((result) => {
      onSuccess(result);
    })
    .catch((err) => {
      onError(err);
    });
};
