import { List, Datagrid, TextField, ResourceComponentProps, Create, SimpleForm, TextInput, DateField, Show, SimpleShowLayout, TopToolbar, DeleteButton, ShowActionsProps, Edit, required, ArrayField, EditButton } from "react-admin";

export class ClientUpdateCommand {
  public name: string

  constructor(data: any) {
    this.name = data.name ?? ""
  }

  static fromJson(data: any) {
    return new ClientUpdateCommand(data)
  }
}

export const ClientList = (props: ResourceComponentProps) => (
  <List {...props}>
    <Datagrid rowClick="show">
      <TextField source="name" />
      <TextField source="id" />
      <DateField source="createdAt" />
    </Datagrid>
  </List>
);

export const ClientCreate = (props: ResourceComponentProps) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" validate={required()} />
    </SimpleForm>
  </Create>
);

export const ClientEdit = (props: ResourceComponentProps) => (
  <Edit {...props}>
      <SimpleForm>
          <TextInput source="name" validate={required()} />
      </SimpleForm>
  </Edit>
);

const ClientShowActions = (props: ShowActionsProps) => (
  <TopToolbar>
    <EditButton basePath={props.basePath} record={props.data} />
    <DeleteButton basePath={props.basePath} record={props.data} />
  </TopToolbar>
);

export const ClientShow = (props: ResourceComponentProps) => (
  <Show actions={<ClientShowActions />} {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <DateField source="createdAt" />
      <ArrayField source="machines">
        <Datagrid rowClick={(id, basePath, record) => `/machineInstance/${record.id}/show`}>
          <TextField source="id" />
          <TextField source="status" />
          <DateField source="lastUpdate" showTime={true}/>
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);