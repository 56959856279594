import {
  List,
  Datagrid,
  TextField,
  ResourceComponentProps,
  Create,
  SimpleForm,
  TextInput,
  DateField,
  Show,
  SimpleShowLayout,
  TopToolbar,
  DeleteButton,
  ShowActionsProps,
  Edit,
  required,
  ReferenceInput,
  SelectInput,
  ReferenceField,
  Link,
  FunctionField,
  linkToRecord,
  NumberInput,
  EditButton,
} from "react-admin";

export class MachineInstanceUpdateCommand {
  public latitude: string;
  public longitude: string;
  public configuration: {
    stopMachineTagValue?: string;
    cuttingProgressFunction?: string;
  };
  public machineDefinitionId: string;
  public siteId: string;
  public version: string;

  constructor(data: any) {
    this.latitude = data.latitude ?? "";
    this.longitude = data.longitude ?? "";
    this.configuration = {
      stopMachineTagValue: data.configuration?.stopMachineTagValue ?? null,
      cuttingProgressFunction:
        data.configuration?.cuttingProgressFunction ?? null,
    };
    this.machineDefinitionId = data.machineDefinition.id ?? "";
    this.siteId = data.site.id ?? "";
    this.version = data.version ?? "";
  }

  static fromJson(data: any) {
    return new MachineInstanceUpdateCommand(data);
  }
}

const MachineInstanceFilters = [
  <ReferenceInput source="clientId" reference="client">
    <SelectInput optionText="name" optionValue="id" />
  </ReferenceInput>,
];

export const MachineInstanceList = (props: ResourceComponentProps) => (
  <List {...props} filters={MachineInstanceFilters}>
    <Datagrid rowClick="show">
      <TextField source="id" label="Machine Name" />
      <TextField source="client.name" label="Client" />
      <TextField source="site.name" label="Site" />
      <DateField source="lastUpdate" showTime={true} />
      <TextField source="site.country" label="Country" />
      <TextField source="site.location" label="Location" />
      <TextField source="status" />
    </Datagrid>
  </List>
);

const cuttingProgressFunctionPlaceholder = `// Receives data object with the following format:
// [{ name: "D100", value: "1.5", timestamp: "2021-10-28T16:56:53.137291Z" }]
// Mandatory to return an object with startDate and endDate
{
  let beginDate, endDate, intervals;

  return {
    startDate,
    endDate,
    intervals,
  };
}`;

export const MachineInstanceCreate = (props: ResourceComponentProps) => (
  <Create {...props}>
    <SimpleForm
      initialValues={{
        configuration: {
          stopMachineTagValue: null,
          cuttingProgressFunction: null,
        },
      }}>
      <TextInput source="id" validate={required()} />

      <ReferenceInput
        source="machineDefinitionId"
        reference="machineDefinition"
        validate={required()}>
        <SelectInput optionText="name" optionValue="id" />
      </ReferenceInput>

      <ReferenceInput source="siteId" reference="site" validate={required()}>
        <SelectInput optionText="name" optionValue="id" />
      </ReferenceInput>

      <TextInput source="version" validate={required()} />

      <NumberInput source="latitude" />
      <NumberInput source="longitude" />

      <TextInput
        source="configuration.stopMachineTagValue"
        label="Stop Command Tag Value"
      />
      <TextInput
        source="configuration.cuttingProgressFunction"
        label="Cutting Progress Function"
        placeholder={cuttingProgressFunctionPlaceholder}
        multiline
        fullWidth
      />
    </SimpleForm>
  </Create>
);

export const MachineInstanceEdit = (props: ResourceComponentProps) => (
  <Edit {...props}>
    <SimpleForm>
      <ReferenceInput
        source="machineDefinition.id"
        reference="machineDefinition"
        validate={required()}>
        <SelectInput optionText="name" optionValue="id" />
      </ReferenceInput>

      <ReferenceInput source="site.id" reference="site" validate={required()}>
        <SelectInput optionText="name" optionValue="id" />
      </ReferenceInput>

      <TextInput source="version" validate={required()} />

      <NumberInput source="latitude" validate={required()} />
      <NumberInput source="longitude" validate={required()} />

      <TextInput
        source="configuration.stopMachineTagValue"
        label="Stop Command Tag Value"
      />
      <TextInput
        source="configuration.cuttingProgressFunction"
        label="Cutting Progress Function"
        placeholder={cuttingProgressFunctionPlaceholder}
        multiline
        fullWidth
      />
    </SimpleForm>
  </Edit>
);

const MachineInstanceShowActions = (props: ShowActionsProps) => (
  <TopToolbar>
    <EditButton basePath={props.basePath} record={props.data} />
    <DeleteButton basePath={props.basePath} record={props.data} />
  </TopToolbar>
);

export const MachineInstanceShow = (props: ResourceComponentProps) => (
  <Show actions={<MachineInstanceShowActions />} {...props}>
    <SimpleShowLayout>
      <TextField source="id" label="Machine Name" />
      <ReferenceField
        label="Client"
        source="client.id"
        reference="client"
        link="show">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="Site"
        source="site.id"
        reference="site"
        link="show">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="version" />
      <DateField source="lastUpdate" />
      <TextField source="latitude" />
      <TextField source="longitude" />
      <TextField source="status" />
      <FunctionField
        label="Machine Definition"
        render={(record: any) =>
          record &&
          record.machineDefinition && (
            <Link
              to={linkToRecord(
                "/machineDefinition",
                record.machineDefinition.id,
                "show"
              )}>
              {record.machineDefinition.name}
            </Link>
          )
        }
      />
      <TextField
        source="configuration.stopMachineTagValue"
        label="Stop Command Tag Value"
      />
      <TextField
        source="configuration.cuttingProgressFunction"
        label="Cutting Progress Function"
      />
    </SimpleShowLayout>
  </Show>
);
